
import i18n from '@/plugins/i18n'
import datasetGiftStatus from '@/datasets/giftStatus'
export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Delivery Status',
          value: 'status',
        },
        {
          text: 'Mochi Id',
          value: 'mochiId',
        },
      ],
    },
  },
  read: {
    tabs: [

      {
        key: 'Info',
        label:  i18n.t("string.info"),
      },

    ],
    table: {
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Classroom',
          value: 'classroomName',
        },
        {
          text: 'Institution',
          value: 'institutionName',
        },
        {
          text: 'Town',
          value: 'town',
        },
        {
          text: 'Delivery Status',
          value: 'status',
        },
        {
          text: 'Mochi Id',
          value: 'mochiId',
        },
        {
          text:  'Gift Sent Date',
          value: 'timestampGiftSent',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: "string",
        key: "institutionName",
        name: 'Institution Name',
      },
      {
        type: "string",
        key: "classroomName",
        name: 'Classroom Name',
      },
      {
        type: 'select',
        key: 'status',
        name: 'Delivery Status',
        options: datasetGiftStatus,
        optionText: 'name',
        optionValue: 'value',
        defaultValue: 'pending',
      }, 
    ],
    rules:false,
  },
  edit: true,
  delete: true,
}