
import Moderator from '@/models/bread/Moderator/finisher'


export default {
    key: 'Finisher',
    name: {
        singular: 'Finisher',
        plural: 'Finishers',
    },
    parents: [
    ],
    Admin: false,
    Moderator: Moderator,
    Teacher: false,
}